<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <DataTable
            class="p-datatable-sm"
            :paginator="true"
            :rows="5"
            stripedRows
            :loading="loading"
            :value="consignacoes"
            dataKey="id"
            :filters.sync="filtros"
            filterDisplay="menu"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} renegociações"
            responsiveLayout="scroll">
            <template #empty> Nenhuma Renegociação encontrada. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <Column field="mesReferencia" header="Mês/Ano">
              <template #body="{ data }">
                {{ ('0' + data.mesReferencia).slice(-2) }}/{{
                  data.anoReferencia
                }}
              </template>
            </Column>
            <Column :sortable="true" field="rubrica" header="Rubrica">
              <template #body="{ data }">
                {{
                  typeof data.rubrica !== 'undefined'
                    ? data.rubrica.rubrica
                    : ''
                }}
                -
                {{
                  typeof data.rubrica !== 'undefined' ? data.rubrica.nome : ''
                }}
              </template>
            </Column>
            <Column :sortable="true" field="numeroContrato" header="Contrato" />
            <Column header="Prazo">
              <template #body="{ data }">
                {{
                  data.prazoRestante + data.prazoTotal == 0
                    ? '-'
                    : data.prazoRestante + '/'
                }}{{ data.prazoTotal == 0 ? '' : data.prazoTotal }}
              </template>
            </Column>
            <Column header="Valor da Parcela">
              <template #body="{ data }">
                <div class="flex justify-content-end flex-wrap card-container">
                  <div class="flex align-items-center justify-content-center">
                    {{ data.valorParcela | formatarValor }}
                  </div>
                </div>
              </template>
            </Column>
            <Column
              :sortable="true"
              field="statusConsignacao"
              header="Situação">
              <template #body="{ data }">
                <Chip
                  v-if="data.statusConsignacao === 'PENDENTE'"
                  label="Pendente"
                  class="mr-2 mb-2 custom-chip pendente" />
                <Chip
                  v-if="data.statusConsignacao === 'ATIVA'"
                  label="Ativa"
                  class="mr-2 mb-2 custom-chip ativa" />
                <Chip
                  v-if="data.statusConsignacao === 'QUITADA'"
                  label="Quitada"
                  class="mr-2 mb-2 custom-chip quitada" />
                <Chip
                  v-if="data.statusConsignacao === 'CANCELADA'"
                  label="Renegociada"
                  class="mr-2 mb-2 custom-chip cancelada" />
                <Chip
                  v-if="data.statusConsignacao === 'SUSPENSA'"
                  label="Suspensa"
                  class="mr-2 mb-2 custom-chip suspensa" />
                <Chip
                  v-if="data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR'"
                  label="Aguardando Supervisor"
                  class="mr-2 mb-2 custom-chip aguardando-supervisor" />
                <Chip
                  v-if="data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'"
                  label="Não Aprovada Supervisor"
                  class="mr-2 mb-2 custom-chip não-aprovada-supervisor" />
                <Chip
                  v-if="data.statusConsignacao === 'BAIXADA'"
                  label="Baixada"
                  class="mr-2 mb-2 custom-chip baixada" />
                <Chip
                  v-if="data.statusConsignacao === 'NAO_DESCONTADA'"
                  label="Não descontada"
                  class="mr-2 mb-2 custom-chip não-descontada" />
                <Chip
                  v-if="data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'"
                  label="Renegociada"
                  class="mr-2 mb-2 custom-chip cancelada" />
                <Chip
                  v-if="data.statusConsignacao === 'SOLICITADA_SUSPENSAO'"
                  label="Suspensa"
                  class="mr-2 mb-2 custom-chip suspensa" />
                <Chip
                  v-if="data.statusConsignacao === 'MOVIMENTO_PARCIAL'"
                  label="Mov. Parcial"
                  class="mr-2 mb-2 custom-chip foda-da-margem" />
                <Chip
                  v-if="data.statusConsignacao === 'FORA_MARGEM'"
                  label="Fora da margem"
                  class="mr-2 mb-2 custom-chip foda-da-margem" />
              </template>
            </Column>
            <Column header="Cadastrado Em">
              <template #body="{ data }">
                {{ data.auditMetadata.cadastradoEm | formatarData }}
              </template>
            </Column>
            <Column header="Cancelado Em">
              <template #body="{ data }">
                {{ data.canceladoEm | formatarData }}
              </template>
            </Column>
            <Column header="Ações">
              <template #body="{ data }">
                <Button
                  v-tooltip.left="'Detalhes'"
                  icon="pi pi-eye"
                  class="p-button-rounded p-button-success"
                  @click="exibirDetalheConsignacao(data)" />
                <Button
                  v-tooltip.left="'Histórico'"
                  icon="pi pi-list"
                  class="mt-2 mr-2 p-button-rounded p-button"
                  @click="exibirHistorico(data.historicoCompraDivida)" />
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
      <Dialog
        :visible.sync="modalHistorico"
        :containerStyle="{ width: '50vw' }">
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="historico"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="rowsPerPageOptions"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} Histórico de Renegociação"
          responsiveLayout="scroll">
          <template #header>Histórico</template>
          <template #empty> Nenhum histórico encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column header="Alterado Em">
            <template #body="{ data }">
              {{ data.alteradoEm | formatarData }}
            </template>
          </Column>
          <Column header="Alterado Por">
            <template #body="{ data }">
              {{ data.nomeAlteradoPor }}
            </template>
          </Column>
          <Column field="descricaoStatus" header="Status" />
        </DataTable>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import { servidorStore } from '@/stores/servidor'
import { consignatariaStore } from '@/stores/consignataria'
import ConsignacaoService from '@/service/ConsignacaoService'
import auth from '@/modules/auth'

export default {
  setup() {
    const store = servidorStore()
    const storeConsignataria = consignatariaStore()
    return { store, storeConsignataria }
  },
  data() {
    return {
      filtros: {},
      consignacao: {
        rubrica: {},
      },
      consignacoes: [],
      idMatricula: this.$route.params.id,
      loading: false,
      situacoes: ['Ativa', 'Quitada', 'Cancelada'],
      auth,
      modalHistorico: false,
      historico: [],
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [5, 10, 25]
    },
  },

  created() {
    this.service = new ConsignacaoService(this.$http)
    this.initFiltros()
    const consignataria = JSON.parse(
      localStorage.getItem('consignatariaSelecionada'),
    )

    this.service
      .getListaConsignacoesRenegociadasPorConsignatariaSelecionadaEMatricula(
        this.idMatricula,
        consignataria.id,
      )
      .then((res) => {
        this.consignacoes = res
      })
      .catch((err) => {
        this.$toast.add({
          severity: 'error',
          summary: err.response.data.message,
          life: 10000,
        })
      })
  },

  methods: {
    exibirHistorico(historico) {
      this.historico = historico
      this.modalHistorico = true
    },
    initFiltros() {
      this.filtros = {
        'statusConsignacao.nome': {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      }
    },

    limparFiltros() {
      this.initFiltros()
    },

    exibirDetalheConsignacao(data) {
      this.$router.push({
        name: 'renegociacao-consignacao-detalhe',
        params: { idconsig: data.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .p-sortable-column-icon {
  margin: 0 0 0 0 !important;
}

button {
  margin: 0 2px;
}
.pendente {
  color: #1f2d3d;
  background-color: #ffc107;
  font-weight: bold;
}
.ativa {
  color: #fff;
  background-color: #007bff;
  font-weight: bold;
}
.cancelada {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
}
.quitada {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
}
</style>
